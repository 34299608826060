import React, { useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { TextType } from "../../data/const/dataType";
import {
  calculateErrorColor,
  editorStyles,
  onScreenClick,
} from "../../data/utils/functions";
import Close from "./components/Close";
import Logo from "./components/Logo";
import { LoadingType } from ".";
import { text } from "stream/consumers";

interface FormType {
  titleForm: TextType;
  question1: TextType;
  error10: TextType;
  label10: TextType;
  question2: TextType;
  error20: TextType;
  error21: TextType;
  label20: TextType;
  label21: TextType;
  question3: TextType;
  error30: TextType;
  error31: TextType;
  label30: TextType;
  label31: TextType;
  question4: TextType;
  question5: TextType;
  error40: TextType;
  label40: TextType;
  label41: TextType;
  label42: TextType;
  label43: TextType;
  button: TextType;
  display?: number;
}

interface FormData {
  form: FormType;
  brand: LoadingType;
}

type StateType = {
  formData: FormData;
};

const Form: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const [weight, updateWeight] = useState(0);
  const [lenght, updateLenght] = useState(0);
  const [gender, updateGender] = useState("female");

  const fontFamily = formData.form.button.fontFamily;
  const background = formData.brand.colorBaground.code;

  const errorColor = useMemo(
    () => calculateErrorColor(background),
    [background]
  );

  return (
    <Box sx={styles.flex}>
      {formData.form.display === 1 && (
        <Box sx={styles.flex}>
          <Close />
          <Logo />
          <Box
            sx={{
              width: "100%",
              paddingY: "24px",
              paddingX: "54px",
            }}
          >
            <Box
              sx={[
                styles.labelForm,
                { fontFamily },
                { textAlign: "center", marginBottom: "20px" },
              ]}
            >
              {
                "Provide the following information to proceed with your face scan."
              }
            </Box>
            <Box
              sx={{
                minWidth: "100%",
              }}
            >
              <Question
                fontFamily={fontFamily}
                value={formData.form.question1.value}
              />
              <Input value="21" fontFamily={fontFamily} />
              <Error
                value={formData.form.error10.value}
                fontFamily={fontFamily}
                errorColor={errorColor}
              />
              <Question
                fontFamily={fontFamily}
                value={formData.form.question3.value}
              />
              <Box sx={styles.box}>
                {lenght == 0 ? (
                  <Input value="110" fontFamily={fontFamily} />
                ) : (
                  <Box sx={styles.heightView}>
                    <Input value="5" fontFamily={fontFamily} />
                    <Input value="11" fontFamily={fontFamily} />
                  </Box>
                )}
                <Switch
                  style={formData?.form?.button}
                  onPressOption1={() => updateLenght(0)}
                  title1={formData.form.label30.value}
                  value={lenght}
                  onPressOption2={() => updateLenght(1)}
                  title2={formData.form.label31.value}
                  fontFamily={fontFamily}
                />
              </Box>
              <Error
                value={
                  lenght == 0
                    ? formData.form.error30.value
                    : formData.form.error31.value
                }
                fontFamily={fontFamily}
                errorColor={errorColor}
              />
              <Question
                fontFamily={fontFamily}
                value={formData.form.question2.value}
              />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <Input value="75" fontFamily={fontFamily} />
                <Switch
                  style={formData?.form?.button}
                  onPressOption1={() => updateWeight(0)}
                  title1={formData.form.label20.value}
                  value={weight}
                  onPressOption2={() => updateWeight(1)}
                  title2={formData.form.label21.value}
                  fontFamily={fontFamily}
                />
              </Box>
              <Error
                value={
                  weight == 0
                    ? formData.form.error20.value
                    : formData.form.error21.value
                }
                fontFamily={fontFamily}
                errorColor={errorColor}
              />
              <Question
                fontFamily={fontFamily}
                value={formData.form.question4.value}
              />
              <Option
                title1={formData.form.label40.value}
                title2={formData.form.label41.value}
                fontFamily={fontFamily}
                option1={"female"}
                option2={"male"}
                seletedValue={gender}
                onChange={updateGender}
                style={formData?.form?.button}
              />
            </Box>
          </Box>
          <Box
            sx={{
              margin: "24px 40px",
              textAlign: "center",
            }}
          >
            <Box
              onClick={() => onScreenClick(7)}
              sx={{
                ...editorStyles(formData.form.button),
                borderWidth: 1,
                borderStyle: "solid",
                borderRadius: 1000,
                padding: "8px 52px",
                cursor: "pointer",
                display: "inline-block",
                whiteSpace: "pre-line",
                fontFamily,
              }}
            >
              {formData.form.button.value}
            </Box>
          </Box>
        </Box>
      )}
      {formData.form.display === 2 && (
        <Box sx={{ color: "#ffffff" }}>Page Hide</Box>
      )}
    </Box>
  );
};

const Input = ({ value, fontFamily }: BoxProps) => (
  <TextField
    size="small"
    variant="outlined"
    placeholder="0"
    value={value}
    sx={[styles.input, { fontFamily }]}
  />
);

const Error = ({ value, fontFamily, errorColor }: BoxProps) => (
  <Box sx={[styles.error, { fontFamily, color: errorColor }]}>{value}</Box>
);

const Question = ({ value, fontFamily }: BoxProps) => (
  <Box sx={[styles.question, { fontFamily }]}>{value}</Box>
);

const Option = ({
  title1,
  title2,
  seletedValue,
  onChange,
  option1,
  option2,
  fontFamily,
  style,
}: OptionProps) => (
  <Box sx={styles.inputForm}>
    <Box sx={styles.buttonArea}>
      <SingleOption
        isActive={seletedValue === option1}
        option={option1}
        title={title1}
        fontFamily={fontFamily}
        onChange={onChange}
        style={style}
      />

      <SingleOption
        isActive={seletedValue === option2}
        option={option2}
        title={title2}
        fontFamily={fontFamily}
        onChange={onChange}
        style={style}
      />
    </Box>
  </Box>
);

const SingleOption = ({
  title,
  option,
  isActive,
  fontFamily,
  style,
  onChange,
}: SingleOptionProps) => (
  <Box
    sx={[
      styles.genderButton,
      {
        backgroundColor: isActive ? style?.background ?? "#21FFAC" : "#F5F5F5",
      },
    ]}
    onClick={() => onChange(option)}
  >
    <Box
      sx={[
        styles.genderButtonText,
        { fontFamily },
        {
          color: isActive ? style?.color ?? "#666666" : "#999999",
        },
      ]}
    >
      {title}
    </Box>
  </Box>
);

const Switch = ({
  style,
  value,
  title1,
  title2,
  fontFamily,
  onPressOption1,
  onPressOption2,
}: SwitchProps) => (
  <Box
    sx={[
      styles.switchArea,
      {
        backgroundColor: style?.background,
      },
    ]}
  >
    <SwitchOption
      title={title2}
      isActive={value == 1}
      onPress={onPressOption2}
      fontFamily={fontFamily}
      style={style}
    />
    <SwitchOption
      title={title1}
      isActive={value == 0}
      onPress={onPressOption1}
      fontFamily={fontFamily}
      style={style}
    />
  </Box>
);

const SwitchOption = ({
  isActive,
  style,
  fontFamily,
  onPress,
  title,
}: SwitchOptionProps) => (
  <Box
    sx={[
      styles.buttonActive,
      { fontFamily },
      {
        backgroundColor: isActive ? style?.color : "transparent",
        color: isActive ? style?.background : style?.color,
      },
    ]}
    onClick={onPress}
  >
    {title}
  </Box>
);

interface BoxProps {
  value: string;
  fontFamily: string;
  errorColor?: string;
}

interface OptionProps {
  title1: string;
  title2: string;
  seletedValue: string;
  onChange: (arg: string) => void;
  option1: string;
  option2: string;
  fontFamily: string;
  style?: any;
}

interface SingleOptionProps {
  title: string;
  option: string;
  isActive: boolean;
  fontFamily: string;
  style?: any;
  onChange: (arg: string) => void;
}

interface SwitchProps {
  style: any;
  value: number;
  title1: string;
  title2: string;
  fontFamily: string;
  onPressOption1: () => void;
  onPressOption2: () => void;
}

interface SwitchOptionProps {
  style: any;
  isActive: boolean;
  title: string;
  fontFamily: string;
  onPress: () => void;
}
const styles = {
  flex: {
    width: "100%",
  },
  input: {
    display: "flex",
    borderColor: "#FFFFFF",
    borderWidth: 2,
    backgroundColor: "#ECECEC",
    fontSize: 22,
    fontWeight: "300",
    borderRadius: "4px",
    marginRight: "5px",
    width: "100%",
  },
  error: {
    color: "#ff5555",
    fontSize: "12px",
    marginTop: "4px",
    whiteSpace: "pre-line",
  },
  question: {
    fontSize: "14px",
    color: "white",
    textAlign: "left",
    fontWeight: "500",
    marginY: "5px",
    marginRight: "32px",
  },
  genderButton: {
    paddingY: "10px",
    paddingX: "18px",
    borderRadius: "6px",
    marginRight: "10px",
    borderColor: "#DDDDDD",
    borderWidth: "1px",
  },
  genderButtonText: {
    fontSize: "14px",
    fontWeight: "600",
  },
  switchArea: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 1000,
    padding: "4px",
  },
  buttonActive: {
    display: "flex",
    color: "#FFFFFF",
    fontSize: "12px",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "28px",
    borderRadius: 100,
  },
  box: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },
  heightView: {
    flexDirection: "row",
    display: "flex",
  },
  labelForm: {
    fontSize: "12px",
    color: "white",
    textAlign: "left",
    fontWeight: "500",
    marginBottom: "8px",
    marginRight: "21px",
  },
  inputForm: {
    display: "flex",
    flexDirection: "row",
  },
  buttonArea: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default Form;
