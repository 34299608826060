import React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { TextType } from "../../data/const/dataType";
import { editorStyles, onScreenClick } from "../../data/utils/functions";
import Close from "./components/Close";
import Logo from "./components/Logo";
import defaultLogo from "../../data/assets/images/placeholder-image.png";
import DownloadIcon from "./components/Download";

interface End {
  titleForm: string;
  content: TextType;
  header: TextType;
  image: any;
  button: any;
  webButton: any;
  downloadable: boolean;
}

interface FormData {
  end: End;
  brand: any;
}

type StateType = {
  formData: FormData;
};

const End: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        position: "relative",
      }}
    >
      <Logo />
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            ...editorStyles(formData.end.header),
            borderWidth: 0,
            background: "none",
            borderStyle: "solid",
            borderRadius: 2,
            textAlign: "center",
            padding: 0,
            whiteSpace: "pre-line",
            marginRight: "12px",
          }}
        >
          {formData.end.header.value}
        </Box>
        {formData?.end?.downloadable && (
          <DownloadIcon color={formData.end.header?.color ?? "white"} />
        )}
      </Box>
      <Box
        sx={{
          padding: "24px 40px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            ...editorStyles(formData.end.content),
            display: "flex",
            justifyContent: "space-between",
            borderRadius: 2,
            alignItems: "center",
            padding: 4,
            whiteSpace: "pre-line",
            backgroundColor: "transparent",
          }}
        >
          {formData.end.content.value}
        </Box>
      </Box>
      {formData.end?.webButton?.value != 0 && (
        <Box
          onClick={() => onScreenClick(2)}
          sx={{
            ...editorStyles(formData.end.webButton),
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 1000,
            padding: "8px 52px",
            whiteSpace: "pre-line",
            marginBottom: "16px",
          }}
        >
          {formData.end?.webButton?.value}
        </Box>
      )}
      <Box
        onClick={() => onScreenClick(2)}
        sx={{
          ...editorStyles(formData.end.button),
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 1000,
          padding: "8px 52px",
          whiteSpace: "pre-line",
        }}
      >
        {formData.end.button.value}
      </Box>
    </Box>
  );
};

export default End;
