import React, { ChangeEvent, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Input from "@mui/material/Input";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ColorPicker from "./ColorPicker";
import { TextType, UploadType } from "../../../data/const/dataType";
import { actions } from "../../../state";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

interface BeginType {
  titleForm: string;
  video: UploadType;
  button: TextType;
}

interface FormData {
  [key: string]: any;
  begin: BeginType;
}

type StateType = {
  formData: FormData;
};

interface EditorProps {
  text?: string;
  onChangeText?: (event: any) => void;
  form: string;
  field: string;
  rows?: number;
  format?: string | null;
  id?: number | null;
  code?: string;
  listView?: boolean;
  onlyColor?: boolean;
}

function getValueFromNestedForm(form: any, field: string): any {
  const fieldKeys = field.split(".");
  let value = form;

  for (const key of fieldKeys) {
    value = value[key];
    if (typeof value === "undefined") {
      // Return undefined if any intermediate key doesn't exist
      return undefined;
    }
  }

  return value;
}

function updateNestedFormAndReturnField(
  form: FormData,
  field: string,
  type: string,
  value: any
): FormData {
  const fieldKeys = field.split(".");
  let updatedForm = { ...form };
  updatedForm.startForm = true;
  let currentObj = updatedForm;

  // Traverse to the second last level of the field
  for (let i = 0; i < fieldKeys.length - 1; i++) {
    const key = fieldKeys[i];
    if (!currentObj.hasOwnProperty(key)) {
      currentObj[key] = {};
    }
    currentObj = currentObj[key];
  }

  // Update the value at the last level of the field
  const lastKey = fieldKeys[fieldKeys.length - 1];
  currentObj[lastKey][type] = value;

  return updatedForm;
}

const Editor: React.FC<EditorProps> = ({
  form,
  field,
  rows,
  format,
  text,
  code,
  listView = false,
  onlyColor = false,
  onChangeText,
}) => {
  const formData = useSelector((state: StateType) => state.formData);
  const stateField = useSelector((state: StateType) =>
    getValueFromNestedForm(formData[form], field)
  );
  const [isList, setIsList] = useState(false);

  const dispatch = useDispatch();

  const handleUpdateState = (type: any, value: any) => {
    dispatch(
      actions.setForm(
        updateNestedFormAndReturnField(
          formData,
          `${form}.${field}`,
          type,
          value
        )
      )
    );
  };

  const handleChangeFamily = (event: SelectChangeEvent) => {
    handleUpdateState("fontFamily", event?.target?.value as string);
  };

  const handleChangeSize = (event: SelectChangeEvent) => {
    handleUpdateState("fontSize", event?.target?.value as string);
  };

  const handleFontBold = (value: boolean) => {
    handleUpdateState("fontWeight", value);
  };

  const handleFontStyle = (value: boolean) => {
    handleUpdateState("fontStyle", value);
  };

  const handleTextDecoration = (value: boolean) => {
    handleUpdateState("textDecoration", value);
  };

  const handleTextAlign = (value: string) => {
    handleUpdateState("textAlign", value);
  };

  const handleColorPickerBackground = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    handleUpdateState("background", event.target?.value);
  };

  const handleColorPickerColor = (event: ChangeEvent<HTMLInputElement>) => {
    handleUpdateState("color", event.target?.value);
  };

  const handleColorPickerBorderColor = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    handleUpdateState("borderColor", event.target?.value);
  };

  const handleEditText = (event: ChangeEvent<HTMLInputElement>) => {
    handleUpdateState("value", event.target?.value);
  };

  const handleBulletedList = () => {
    let list = !isList;
    handleUpdateState("isList", list);
    setIsList(list);
  };

  return onlyColor ? (
    <>
      <Box className="form-subtitle" mt={2}>
        Choose background color
      </Box>
      <ColorPicker
        color={stateField.background}
        handleColorPicker={handleColorPickerBackground}
      />
    </>
  ) : (
    <>
      <Box
        sx={{
          position: "relative",
          border: "1px solid #C0C0C0",
          paddingBottom: format !== "hide" ? "50px" : "0",
          borderRadius: "4px",
        }}
      >
        <Input
          id={form}
          size="small"
          onChange={code == "MSI" ? onChangeText : handleEditText}
          value={code == "MSI" ? text : stateField?.value}
          multiline
          maxRows={rows}
          placeholder="Type here"
          sx={{
            width: "100%",
            border: "none",
            padding: "8px",
            "&:before": {
              content: "none",
            },
            "&:after": {
              content: "none",
            },
          }}
        />
        {format !== "hide" && (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              padding: "8px",
              height: "40px",
              display: "flex",
            }}
          >
            <FormControl size="small" sx={{ marginRight: 1 }}>
              <Select
                value={stateField.fontFamily.toString()}
                onChange={handleChangeFamily}
                sx={{
                  width: 80,
                  height: 24,
                  borderColor: "#DFDFDF",
                  background: "#f7f7f7",
                }}
              >
                <MenuItem value="Arial">Arial</MenuItem>
                <MenuItem value="Monospace">Monospace</MenuItem>
                <MenuItem value="Serif">Serif</MenuItem>
                <MenuItem value="SpaceGrotesk">Space Grotesk</MenuItem>
                <MenuItem value="Gatorade">Gatorade</MenuItem>
                <MenuItem value="Gilroy">Gilroy</MenuItem>
                <MenuItem value="Quaker">Quaker</MenuItem>
                <MenuItem value="Segoe">Segoe</MenuItem>
                <MenuItem value="Futura">Futura</MenuItem>
              </Select>
            </FormControl>
            <FormControl size="small" sx={{ marginRight: 1 }}>
              <Select
                value={stateField.fontSize.toString()}
                onChange={handleChangeSize}
                sx={{
                  width: 65,
                  height: 24,
                  borderColor: "#DFDFDF",
                  background: "#f7f7f7",
                }}
              >
                {Array.from({ length: 117 }, (_, index) => index + 4).map(
                  (e) => {
                    return (
                      <MenuItem value={e} key={e}>
                        {e}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
            <Box display="flex" justifyContent="center" alignItems="center">
              <FormatBoldIcon
                onClick={() => handleFontBold(!stateField.fontWeight)}
                style={{
                  color: stateField.fontWeight ? "white" : "#3A3A3A",
                  background: stateField.fontWeight ? "#3A3A3A" : "white",
                  fontSize: "20px",
                  cursor: "pointer",
                  marginRight: "4px",
                }}
              />
              <FormatItalicIcon
                onClick={() => handleFontStyle(!stateField.fontStyle)}
                style={{
                  color: stateField.fontStyle ? "white" : "#3A3A3A",
                  background: stateField.fontStyle ? "#3A3A3A" : "white",
                  fontSize: "20px",
                  cursor: "pointer",
                  marginRight: "4px",
                }}
              />
              <FormatUnderlinedIcon
                onClick={() => handleTextDecoration(!stateField.textDecoration)}
                style={{
                  color: stateField.textDecoration ? "white" : "#3A3A3A",
                  background: stateField.textDecoration ? "#3A3A3A" : "white",
                  fontSize: "20px",
                  cursor: "pointer",
                  marginRight: "4px",
                }}
              />

              <FormatAlignLeftIcon
                onClick={() => handleTextAlign("left")}
                style={{
                  color: stateField.textAlign === "left" ? "white" : "#3A3A3A",
                  background:
                    stateField.textAlign === "left" ? "#3A3A3A" : "white",
                  fontSize: "20px",
                  cursor: "pointer",
                  marginRight: "4px",
                }}
              />

              <FormatAlignCenterIcon
                onClick={() => handleTextAlign("center")}
                style={{
                  color:
                    stateField.textAlign === "center" ? "white" : "#3A3A3A",
                  background:
                    stateField.textAlign === "center" ? "#3A3A3A" : "white",
                  fontSize: "20px",
                  cursor: "pointer",
                  marginRight: "4px",
                }}
              />

              <FormatAlignRightIcon
                onClick={() => handleTextAlign("right")}
                style={{
                  color: stateField.textAlign === "right" ? "white" : "#3A3A3A",
                  background:
                    stateField.textAlign === "right" ? "#3A3A3A" : "white",
                  fontSize: "20px",
                  cursor: "pointer",
                  marginRight: "4px",
                }}
              />
              {listView && (
                <FormatListBulletedIcon
                  onClick={handleBulletedList}
                  style={{
                    color: stateField.isList ? "white" : "#3A3A3A",
                    background: stateField.isList ? "#3A3A3A" : "white",
                    fontSize: "20px",
                    cursor: "pointer",
                    marginRight: "4px",
                  }}
                />
              )}
              <Box sx={{ position: "relative", height: "20px" }}>
                <FormatColorTextIcon
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    marginRight: "4px",
                    color: "#3A3A3A",
                  }}
                />
                <Box
                  className="inputSelectColor"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    opacity: 0,
                    background: stateField.color,
                  }}
                >
                  <Input
                    name="color"
                    type="color"
                    value={stateField.color}
                    onChange={handleColorPickerColor}
                    style={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {format === "button" && (
        <>
          <Box className="form-subtitle" mt={2}>
            Choose a color for your button
          </Box>
          <ColorPicker
            color={stateField.background}
            handleColorPicker={handleColorPickerBackground}
          />
          <Box className="form-subtitle" mt={2}>
            Choose your button border color
          </Box>
          <ColorPicker
            color={stateField.borderColor}
            handleColorPicker={handleColorPickerBorderColor}
          />
        </>
      )}
    </>
  );
};

export default Editor;
