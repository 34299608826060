import React from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { UploadType } from "../../../data/const/dataType";
import ClearIcon from "@mui/icons-material/Clear";
import { actions } from "../../../state";

interface BrandType {
  footer: UploadType;
}
interface FormData {
  formActive: number;
  brand: BrandType;
}

type StateType = {
  formData: FormData;
};

const Footer = () => {
  const formData = useSelector((state: StateType) => state.formData);

  if (!formData.brand.footer.src) return <></>;

  const dispatch = useDispatch();

  const removeImage = () => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        brand: {
          ...formData.brand,
          footer: {
            src: "",
            upload: null,
            blob: "",
            link: "",
          },
        },
      })
    );
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: formData.brand.footer.background || "white",
          opacity: 0.1, // Apply opacity only to the background
        }}
      />
      <Box
        sx={{
          position: "relative", // Ensure the content stays above the background
          zIndex: 1, // Higher stacking order than the background
        }}
      >
        <img
          src={formData.brand.footer.src}
          style={{ width: "81px", height: "24px", objectFit: "contain" }}
          alt=""
        />
      </Box>
      {formData.formActive === 1 && (
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            width: "30px",
            height: "30px",
            top: "-10px",
            right: "0",
            background: "black",
            borderRadius: "30px",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={removeImage}
        >
          <ClearIcon
            sx={{
              width: "15px",
              height: "15px",
              color: "white",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default Footer;
