import React from "react";

const DownloadIcon = ({ color = "#000", size = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={color}
    >
      <path d="M11 5h2v6h3.59L12 15.59 7.41 11H11V5z" /> {/* Arrow */}
      <path d="M4 18c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-.5c0-.28-.22-.5-.5-.5H4.5c-.28 0-.5.22-.5.5v.5z" />{" "}
      {/* Curvy bottom line */}
    </svg>
  );
};

export default DownloadIcon;
