import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Editor from "./components/Editor";
import TitleForm from "./components/TitleForm";
import { TextType, UploadType } from "../../data/const/dataType";
import AccordionForms from "./components/AccordionForms";
import { actions } from "../../state";
import Typography from "@mui/material/Typography";
import { Input, Switch } from "@mui/material";

interface EndType {
  downloadable: boolean;
  titleForm: string;
  content: TextType;
  image: UploadType;
  webButtonURL: string;
}

interface FormData {
  end: EndType;
  formActive: number;
}

type StateType = {
  formData: FormData;
  filterData: any;
};

const End: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const filterData = useSelector((state: StateType) => state.filterData);

  const dispatch = useDispatch();

  const toggleDownloadFunctionality = () => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        end: {
          ...formData.end,
          downloadable: !formData.end.downloadable,
        },
      })
    );
  };

  const updateURL = (event: any) => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        end: {
          ...formData.end,
          webButtonURL: event?.target?.value as string,
        },
      })
    );
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TitleForm title={formData.end.titleForm} />

      <Box className="scroll-form-accordion">
        <AccordionForms title="Add your text">
          <Box
            sx={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography className="subtitle-accordion">
              <span style={{ whiteSpace: "nowrap" }}>
                Make Results downloadable
              </span>
            </Typography>
            <Switch
              checked={formData.end.downloadable ?? false}
              onChange={toggleDownloadFunctionality}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <Typography className="subtitle-accordion">Title</Typography>
          <Editor form="end" field="header" rows={1} />
          <Typography className="subtitle-accordion">Explanation</Typography>
          <Editor form="end" field="content" rows={4} />
        </AccordionForms>

        <AccordionForms title="Customize Web button">
          <Typography className="subtitle-accordion">Button Text</Typography>
          <Editor form="end" field="webButton" rows={1} format="button" />
          <Typography className="subtitle-accordion">Button url</Typography>
          <Box
            sx={{
              position: "relative",
              border: "1px solid #C0C0C0",
              borderRadius: "4px",
            }}
          >
            <Input
              onChange={updateURL}
              value={formData?.end?.webButtonURL}
              placeholder="https://..."
              sx={{
                width: "100%",
                border: "none",
                padding: "8px",
                "&:before": {
                  content: "none",
                },
                "&:after": {
                  content: "none",
                },
              }}
            />
          </Box>
        </AccordionForms>

        <AccordionForms title="Customize button">
          <Typography className="subtitle-accordion">Button Text</Typography>
          <Editor form="end" field="button" rows={1} format="button" />
        </AccordionForms>
      </Box>
    </Box>
  );
};

export default End;
