import React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { TextType, UploadType } from "../../data/const/dataType";
import { editorStyles, onScreenClick } from "../../data/utils/functions";

interface BeginType {
  titleForm: string;
  video: UploadType;
  button: TextType;
}

interface FormData {
  begin: BeginType;
  activeProgram: number;
  startForm: boolean;
}

type StateType = {
  formData: FormData;
  filterData: any;
};

const Begin: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);

  return (
    <>
      {formData.begin.video.src ? (
        <video
          autoPlay={true}
          muted={true}
          playsInline={true}
          loop={true}
          src={formData.begin.video.src}
          style={{
            height: "683px",
            width: "100%",
            objectFit: "cover",
          }}
        ></video>
      ) : (
        <Box
          sx={{
            height: "683px",
            display: "flex",
            width: "100%",
            backgroundColor: "grey",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          ----- Video here -----
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          bottom: 16,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          onClick={() => onScreenClick(4)}
          sx={{
            ...editorStyles(formData.begin.button),
            padding: "8px 52px",
            borderRadius: 1000,
            borderWidth: 1,
            borderStyle: "solid",
            cursor: "pointer",
            whiteSpace: "pre-line",
          }}
        >
          {formData.begin.button.value}
        </Box>
      </Box>
    </>
  );
};

export default Begin;
