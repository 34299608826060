import React, { ChangeEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Upload from "./components/Upload";
import Editor from "./components/Editor";
import Typography from "@mui/material/Typography";
import TitleForm from "./components/TitleForm";
import AccordionForms from "./components/AccordionForms";
import { TextType } from "../../data/const/dataType";
import { actions } from "../../state";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

interface WelcomeType {
  titleForm: string;
  display: boolean;
  img: any;
  button: TextType;
}

interface FormData {
  welcome: WelcomeType;
  formActive: number;
}

type StateType = {
  formData: FormData;
  filterData: any;
};

const Welcome: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);

  const dispatch = useDispatch();

  const handleImageSave = (value: string, file: any) => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        welcome: {
          ...formData.welcome,
          img: {
            ...formData.welcome.img,
            src: value,
            upload: file,
          },
        },
      })
    );
  };

  const handleDisplayForm = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        welcome: {
          ...formData.welcome,
          display: parseInt(event.target.value),
        },
      })
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
          marginTop: "64px",
          background: "#EFF5FC",
          padding: "16px",
          color: "#3A3A3A",
          fontWeight: "700",
          fontSize: "18px",
          flexDirection: "column",
        }}
      >
        {formData.welcome.titleForm}
        <Box>
          <RadioGroup
            row
            value={formData.welcome.display}
            onChange={handleDisplayForm}
          >
            <FormControlLabel value={1} control={<Radio />} label="Display" />
            <FormControlLabel value={0} control={<Radio />} label="Hide" />
          </RadioGroup>
          <Typography variant="caption">
            This page is optional and you can choose to not display
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          overflow: "scroll",
          height: "calc(100vh - 190px)",
          paddingBottom: "100px",
        }}
      >
        <AccordionForms title="Add header Image">
          <Typography className="subtitle-accordion">
            Upload your image using an external link
          </Typography>
          <Upload type="image" handleSave={handleImageSave} />
        </AccordionForms>
        <AccordionForms title="Customize your button">
          <Typography className="subtitle-accordion">Button Text</Typography>
          <Editor form="welcome" field="button" rows={1} format="button" />
        </AccordionForms>
      </Box>
    </Box>
  );
};

export default Welcome;
