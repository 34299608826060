import React from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { editorStyles, onScreenClick } from "../../data/utils/functions";
import { TextType } from "../../data/const/dataType";
import defaultLogo from "../../data/assets/images/placeholder-image.png";
import { actions } from "../../state";
import ClearIcon from "@mui/icons-material/Clear";

interface StressRow {
  description: TextType;
  images: {
    src: string;
    blob: string;
    link: string;
    upload: any;
  }[];
}

interface StressDetail {
  title: string;
  header: TextType;
  row1: StressRow;
  row2: StressRow;
}

interface ProductType {
  titleForm: string;
  loading: boolean;
  header: TextType;
  stressDetails: [StressDetail, StressDetail];
  help: TextType;
  primaryButton: TextType;
  secondaryButton: TextType;
  explanation: TextType;
  activeTab: "0" | "1";
  display: 0 | 1;
  criteria: "MSI" | "HYD";
}

interface FormData {
  product: ProductType;
  results: any;
}

export type StateType = {
  formData: FormData;
};

const Product = () => {
  const formData = useSelector((state: StateType) => state.formData);

  let stressDetails =
    formData?.product?.stressDetails[formData?.product?.activeTab || 0];

  const moveToEnd = () => onScreenClick(10);

  const dispatch = useDispatch();

  const removeImage = (index: number, row: "row1" | "row2") => {
    let activeTab = parseInt(formData?.product?.activeTab || "0");
    let images = formData.product.stressDetails[activeTab][row].images;
    images[index] = {
      src: "",
      upload: null,
      blob: "",
      link: "",
    };

    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        product: {
          ...formData.product,
          stressDetails: {
            ...formData.product.stressDetails,
            [activeTab]: {
              ...formData.product.stressDetails[activeTab],
              [row]: {
                ...formData.product.stressDetails[activeTab][row],
                images,
              },
            },
          },
        },
      })
    );
  };

  return (
    <Box
      width={"100%"}
      height={"100%"}
      sx={{
        padding: "20px",
      }}
      bgcolor={"white"}
      alignItems={"center"}
    >
      {stressDetails.header.value && (
        <Box
          sx={{
            ...editorStyles(stressDetails.header),
            marginBottom: "10px",
            whiteSpace: "pre-line",
          }}
        >
          {stressDetails.header.value}
        </Box>
      )}

      {stressDetails.row1.description.value && (
        <Box
          sx={{
            ...editorStyles(stressDetails.row1.description),
            marginBottom: "20px",
            whiteSpace: "pre-line",
          }}
        >
          {stressDetails.row1.description.value}
        </Box>
      )}

      <Box
        width={"100%"}
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        {stressDetails.row1.images.map((image, i) => (
          <Image data={image} key={`image-row1-${i}`} />
        ))}
      </Box>

      {stressDetails.row2.description.value && (
        <Box
          sx={{
            ...editorStyles(stressDetails.row2.description),
            whiteSpace: "pre-line",
          }}
        >
          {stressDetails.row2.description.value}
        </Box>
      )}

      <Box
        width={"100%"}
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        {stressDetails.row2.images.map((image, i) => (
          <Image
            data={image}
            key={`image-row2-${i}`}
            removeImage={() => removeImage(i, "row2")}
          />
        ))}
      </Box>

      <Box
        sx={{
          marginTop: "40px",
          ...editorStyles(formData.product.help),
          whiteSpace: "pre-line",
        }}
      >
        {formData.product.help.value}
      </Box>

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        {/* <Box
          onClick={moveToEnd}
          sx={{
            ...editorStyles(formData.product.primaryButton),
            padding: "8px",
            width: "150px",
            borderRadius: 2,
            borderWidth: 1,
            borderStyle: "solid",
            cursor: "pointer",
            boxShadow: 10,
            whiteSpace: "pre-line",
          }}
        >
          {formData.product.primaryButton.value}
        </Box> */}
        <Box
          onClick={moveToEnd}
          sx={{
            ...editorStyles(formData.product.secondaryButton),
            marginLeft: "10px",
            padding: "8px",
            width: "150px",
            borderRadius: 1000,
            borderWidth: 1,
            borderStyle: "solid",
            cursor: "pointer",
            boxShadow: 10,
            whiteSpace: "pre-line",
          }}
        >
          {formData.product.secondaryButton.value}
        </Box>
      </Box>

      <Box
        sx={{
          ...editorStyles(formData.product.explanation),
          whiteSpace: "pre-line",
        }}
      >
        {formData.product.explanation.value}
      </Box>
    </Box>
  );
};

const Image = ({
  data,
  removeImage,
}: {
  data?: any;
  removeImage?: () => void;
}) => {
  return data?.src ? (
    <Box
      sx={{
        position: "relative",
        background: "#ffffff",
        borderRadius: "4px",
        boxShadow: 4,
        width: "150px",
        height: "180px",
        marginRight: "10px",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          flexWrap: "wrap",
        }}
      >
        <img
          src={data?.src || defaultLogo}
          style={{
            width: "135px",
            height: "120px",
            objectFit: "contain",
          }}
          alt=""
        />
        {data?.brand && (
          <Box
            sx={{
              marginRight: "5px",
              marginLeft: "5px",
              fontSize: "12px",
              fontWeight: "bold",
              wordWrap: "break-word",
              textAlign: "left",
              color: "black",
            }}
          >
            {data?.brand || ""}
          </Box>
        )}
        {data?.flavor && (
          <Box
            sx={{
              marginRight: "5px",
              marginLeft: "5px",
              fontSize: "12px",
              wordWrap: "break-word",
              textAlign: "left",
              color: "black",
            }}
          >
            {data?.flavor || ""}
          </Box>
        )}
      </Box>
      {removeImage && (
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            width: "20px",
            height: "20px",
            top: "-5px",
            right: "-5px",
            background: "black",
            borderRadius: "20px",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={removeImage}
        >
          <ClearIcon
            sx={{
              width: "15px",
              height: "15px",
              color: "white",
            }}
          />
        </Box>
      )}
    </Box>
  ) : null;
};
export default Product;
