import { put, select } from "redux-saga/effects";
import { actions } from "..";
import { getSafe } from "../../data/utils/functions";
import { upsertProgrammeConfig, uploadAssets } from "../../data/api/endpoints";
import { editorStylesData } from "../../data/utils/functions";

export default function* getUpsertProgrammeConfigurationSaga(param) {
  try {
    const state = yield select();
    const { type } = param.payload;
    const programmeId = state.formData.activeProgram;
    let configuration = [];
    switch (state.formData.formActive) {
      case 1: //brand screen
        let statusImage =
          state.formData.brand.logo.src !== state.formData.tempForm.logo.src;
        let upload = { status: 200 };
        if (statusImage) {
          upload = yield uploadAssets(
            state.formData.brand.logo.upload,
            programmeId,
            "globals"
          );
        }

        let footerImage =
          state.formData.brand.footer.upload &&
          state.formData.brand.footer.src !==
            state.formData.tempForm.footer.src;
        let footerUpload = { status: 200 };
        if (footerImage) {
          footerUpload = yield uploadAssets(
            state.formData.brand.footer.upload,
            programmeId,
            "footer"
          );
        }

        if (
          !upload.status ||
          upload.status === 200 ||
          footerUpload.status == 200 ||
          !footerUpload.status
        ) {
          configuration.push({
            componentId: "globals",
            // "asset": state.formData.brand.logo.blob,
            style: {
              brandBackgroundColor: state.formData.brand.colorBaground.code,
              gradient: state.formData.brand.gradientBaground.code,
              crossButtonBackgroundColor:
                state.formData.brand.closeBackground.code,
              crossButtonTextColor: state.formData.brand.closeColor.code,
              footer: state.formData.brand.footer?.background ?? "#ffffff",
            },
            data: {
              brandLogoBlob:
                // getSafe(() => upload?.data?.data) ||
                state.formData.brand.logo.blob,
              isLandscape: state.formData.brand?.isLandscape ?? false,
              footerBlob:
                getSafe(() => footerUpload?.data?.data) ||
                state.formData.brand.footer.blob,
            },
          });
        }
        break;
      case 2:
        let welcomeImg = 0;
        if (state?.formData?.welcome?.img?.upload) {
          welcomeImg = yield uploadAssets(
            state.formData.welcome?.img?.upload,
            programmeId,
            "WelcomeImage"
          );
        }

        configuration.push(
          ...[
            {
              componentId: "welcomeScreenContent",
              parentComponentId: "welcomeScreen",
              // "asset":"",
              data: {
                isDisplay: state.formData.welcome.display ?? 0,
              },
            },
            {
              componentId: "welcomeScreenImage",
              parentComponentId: "welcomeScreen",
              // "asset":"",
              data: {
                imageBlob:
                  welcomeImg?.data?.data || state.formData?.welcome?.img?.blob,
              },
            },
            {
              componentId: "welcomeScreenButton",
              parentComponentId: "welcomeScreen",
              style: {
                ...editorStylesData(state.formData.welcome.button),
              },
              data: {
                label: state.formData.welcome.button.value,
              },
            },
          ]
        );
        break;
      case 3: //begin video screen
        let beginVideo = 0;
        if (state?.formData?.begin.video?.upload) {
          beginVideo = yield uploadAssets(
            state.formData.begin.video.upload,
            programmeId,
            "BeginVideo"
          );
        }

        configuration.push(
          ...[
            {
              componentId: "beginScreenVideo",
              parentComponentId: "beginScreen",
              // "asset":"",
              data: {
                videoBlob:
                  beginVideo?.data?.data || state.formData.begin.video.blob,
              },
            },
            {
              componentId: "beginScreenButton",
              parentComponentId: "beginScreen",
              style: {
                ...editorStylesData(state.formData.begin.button),
              },
              data: {
                label: state.formData.begin.button.value,
              },
            },
          ]
        );
        break;
      case 4: // how scan works
        let scanLogo = 0;
        if (state?.formData?.howScanWork?.image?.upload) {
          scanLogo = yield uploadAssets(
            state.formData.howScanWork.image.upload,
            programmeId,
            "howScanWorkImage"
          );
        }

        configuration.push(
          ...[
            {
              componentId: "howScanWorkTitle",
              parentComponentId: "howScanWorkScreen",
              style: {
                ...editorStylesData(state.formData.howScanWork.header),
              },
              data: {
                label: state.formData.howScanWork.header.value,
              },
            },
            {
              componentId: "howScanWorksCheckbox",
              parentComponentId: "howScanWorkScreen",
              style: {
                ...editorStylesData(state.formData.howScanWork.checkbox),
              },
              data: {
                label: state.formData.howScanWork.checkbox.value,
              },
            },
            {
              componentId: "howScanWorksContent",
              parentComponentId: "howScanWorkScreen",
              style: {
                ...editorStylesData(state.formData.howScanWork.content),
              },
              data: {
                label: state.formData.howScanWork.content.value,
              },
            },
            {
              componentId: "howScanWorksOkGotItButton",
              parentComponentId: "howScanWorkScreen",
              style: {
                ...editorStylesData(state.formData.howScanWork.button),
              },
              data: {
                label: state.formData.howScanWork.button.value,
              },
            },
            {
              componentId: "howScanWorkImage",
              parentComponentId: "howScanWorkScreen",
              data: {
                imageBlob:
                  scanLogo?.data?.data || state.formData.howScanWork.image.blob,
              },
            },
            {
              componentId: "howScanWorksDisclaimerContent",
              parentComponentId: "howScanWorkScreen",
              style: {
                ...editorStylesData(state.formData.howScanWork.contentBottom),
              },
              data: {
                label: state.formData.howScanWork.contentBottom.value,
              },
            },
          ]
        );
        break;
      case 5: // Terms & Conditions
        let qrCode = 0;
        if (state?.formData?.termsConditions?.image?.upload) {
          qrCode = yield uploadAssets(
            state.formData.termsConditions.image.upload,
            programmeId,
            "termsAndConditionQRCodeImage"
          );
        }
        configuration.push(
          ...[
            {
              componentId: "termsAndConditionTitle",
              parentComponentId: "termsAndConditionScreen",
              style: {
                ...editorStylesData(state.formData.termsConditions.header),
              },
              data: {
                label: state.formData.termsConditions.header.value,
              },
            },
            {
              componentId: "termsAndConditionContent",
              parentComponentId: "termsAndConditionScreen",
              style: {
                ...editorStylesData(state.formData.termsConditions.content),
              },
              data: {
                label: state.formData.termsConditions.content.value,
              },
            },
            {
              componentId: "termsAndConditionQRCode",
              parentComponentId: "termsAndConditionScreen",
              data: {
                qrCodeBlob:
                  qrCode?.data?.data ||
                  state?.formData?.termsConditions?.image?.blob,
              },
            },
            {
              componentId: "termsAndConditionIUnderstandButton",
              parentComponentId: "termsAndConditionScreen",
              style: {
                ...editorStylesData(state.formData.termsConditions.button),
              },
              data: {
                label: state.formData.termsConditions.button.value,
              },
            },
            {
              componentId: "termsAndConditionDisclaimerContent",
              parentComponentId: "termsAndConditionScreen",
              style: {
                ...editorStylesData(
                  state.formData.termsConditions.contentBottom
                ),
              },
              data: {
                label: state.formData.termsConditions.contentBottom.value,
              },
            },
          ]
        );
        break;
      case 6: // Patient Infomation Screen
        configuration.push(
          ...[
            {
              componentId: "patientInfoQuestion",
              parentComponentId: "patientInfoScreen",
              data: {
                ageQuestion: state.formData.form.question1.value,
                ageErrorMessage: state.formData.form.error10.value,
                years: state.formData.form.label10.value,
                weightQuestion: state.formData.form.question2.value,
                weightErrorForKg: state.formData.form.error20.value,
                weightErrorForLbs: state.formData.form.error21.value,
                kg: state.formData.form.label20.value,
                lbs: state.formData.form.label21.value,
                heightQuestion: state.formData.form.question3.value,
                heightErrorForCM: state.formData.form.error30.value,
                heightErrorForFeet: state.formData.form.error31.value,
                heightErrorForInch: state.formData.form.error40.value,
                cm: state.formData.form.label30.value,
                ft: state.formData.form.label31.value,
                genderQuestion: state.formData.form.question4.value,
                female: state.formData.form.label40.value,
                male: state.formData.form.label41.value,
                hydrationQuestion: state.formData.form.question5.value,
                hydrated: state.formData.form.label42.value,
                not_hydrated: state.formData.form.label43.value,
                isDisplay: state.formData.form.display,
              },
            },
            {
              componentId: "patientInfoNextButton",
              parentComponentId: "patientInfoScreen",
              style: {
                ...editorStylesData(state.formData.form.button),
              },
              data: {
                label: state.formData.form.button.value,
              },
            },
          ]
        );
        break;
      case 7: // Face Scan Screen - No configuration required
        break;
      case 8: // Result Screen
        configuration.push(
          ...[
            {
              componentId: "resultScreenTitle",
              parentComponentId: "resultScreen",
              style: {
                ...editorStylesData(state.formData.results.header),
              },
              data: {
                label: state.formData.results.header.value,
              },
            },
            {
              componentId: "resultScreenDisclaimer",
              parentComponentId: "resultScreen",
              style: {
                ...editorStylesData(state.formData.results.footer),
              },
              data: {
                label: state.formData.results.footer.value,
              },
            },
            {
              componentId: "downloadAppButton",
              parentComponentId: "resultScreen",
              style: {
                ...editorStylesData(state.formData.results.download),
              },
              data: {
                label: state.formData.results.download.value,
                isDisplay: state.formData.results.showDownloadButton,
              },
            },
            {
              componentId: "resultScreenNextButton",
              parentComponentId: "resultScreen",
              style: {
                ...editorStylesData(state.formData.results.nextButton),
              },
              data: {
                label: state.formData.results.nextButton.value,
              },
            },
          ]
        );
        let widgets = state.formData.results.selectedWidget.map((w) => {
          let widget = {
            code: w.code,
            title: w.titleWidget.value,
            background: w.background ?? "#0000001a",
            showIcon: w.showIcon ?? false,
            titleStyle: editorStylesData(w.titleWidget),
            buttonLabel: w.button.value,
            buttonStyle: editorStylesData(w.button),
            explanationLabel: w.explanation.value,
            explanationStyle: editorStylesData(w.explanation),
            explanationButtonLabel: w.explanationButton.value,
            explanationButton: editorStylesData(w.explanationButton),
            ...(w.code == "MSI" || w.code == "HYD"
              ? {
                  lowLabel: w?.lowValue?.value || "",
                  highLabel: w?.highValue?.value || "",
                }
              : {}),
          };
          if (w.code == "MSI") {
            widget.explanationLabel = btoa(
              JSON.stringify(
                w?.indexes?.map(({ state, explaination }) => ({
                  state,
                  explaination,
                })) || []
              )
            );
            widget.type = w.type;
          } else {
            widget.explanationLabel = w?.explanation?.value || "";
          }
          return widget;
        });
        var widgetComponent = {
          componentId: "resultWidget",
          parentComponentId: "resultScreen",
          data: {
            widgets: widgets,
          },
        };
        configuration.push(widgetComponent);
        break;
      // product recomendation screen
      case 9:
        let updatedConfiguration = [
          {
            componentId: "productScreenVisibility",
            parentComponentId: "productScreen",
            data: {
              isDisplay: state.formData.product.display,
              criteria: state.formData.product.criteria || "MSI",
            },
          },
          {
            componentId: "productScreenHelpText",
            parentComponentId: "productScreen",
            style: {
              ...editorStylesData(state.formData.product.help),
            },
            data: {
              label: state.formData.product.help.value,
            },
          },
          {
            componentId: "productScreenPrimaryButton",
            parentComponentId: "productScreen",
            style: {
              ...editorStylesData(state.formData.product.primaryButton),
            },
            data: {
              label: state.formData.product.primaryButton.value,
            },
          },
          {
            componentId: "productScreenSecondaryButton",
            parentComponentId: "productScreen",
            style: {
              ...editorStylesData(state.formData.product.secondaryButton),
            },
            data: {
              label: state.formData.product.secondaryButton.value,
            },
          },
          {
            componentId: "productScreenExplaination",
            parentComponentId: "productScreen",
            style: {
              ...editorStylesData(state.formData.product.explanation),
            },
            data: {
              label: state.formData.product.explanation.value,
            },
          },
          // update text if product screen visibility change
          {
            componentId: "downloadAppButton",
            parentComponentId: "resultScreen",
            style: {
              ...editorStylesData(state.formData.results.download),
            },
            data: {
              label: state.formData.results.download.value,
            },
          },
        ];

        for (let i = 0; i < 2; i++) {
          updatedConfiguration.push({
            componentId: `productScreenTitleIndex${i}`,
            parentComponentId: "productScreen",
            style: {
              ...editorStylesData(
                state.formData.product.stressDetails[i]?.header
              ),
            },
            data: {
              label: state.formData.product.stressDetails[i]?.header?.value,
            },
          });
          for (let j = 1; j <= 2; j++) {
            let config = {
              componentId: `productScreenStressIndex${i}Row${j}`,
              parentComponentId: "productScreen",
              style: {
                ...editorStylesData(
                  state.formData.product.stressDetails[i][`row${j}`].description
                ),
              },
              data: {
                label:
                  state.formData.product.stressDetails[i][`row${j}`].description
                    .value,
              },
            };
            for (
              let k = 0;
              k <
              state?.formData?.product?.stressDetails[i][`row${j}`]?.images
                .length;
              k++
            ) {
              if (
                state?.formData?.product?.stressDetails[i][`row${j}`]?.images[k]
                  ?.upload
              ) {
                let image = yield uploadAssets(
                  state.formData.product.stressDetails[i][`row${j}`].images[k]
                    .upload,
                  programmeId,
                  `productScreenStressIndex${i}Row${j}image${k}`
                );
                config.data[`image${k}`] = {
                  link: state.formData.product.stressDetails[i][`row${j}`]
                    .images[k].link,
                  blob: image?.data?.data || "",
                };
              } else {
                config.data[`image${k}`] = {
                  ...state.formData.product.stressDetails[i][`row${j}`].images[
                    k
                  ],
                };
              }
            }
            updatedConfiguration.push(config);
          }
        }
        configuration.push(...updatedConfiguration);
        break;
      case 10: // Download App Screen
        configuration.push(
          ...[
            {
              componentId: "downloadAppContent",
              parentComponentId: "downloadAppScreen",
              style: {
                ...editorStylesData(state.formData.end.content),
              },
              data: {
                label: state.formData.end.content.value,
              },
            },
            {
              componentId: "downloadScreenTitle",
              parentComponentId: "downloadAppScreen",
              style: {
                ...editorStylesData(state.formData.end.header),
              },
              data: {
                label: state.formData.end.header.value,
                downloadable: state.formData.end?.downloadable ?? false,
              },
            },
            {
              componentId: "nextUserButton",
              parentComponentId: "downloadAppScreen",
              style: {
                ...editorStylesData(state.formData.end.button),
              },
              data: {
                label: state.formData.end.button.value,
              },
            },
            {
              componentId: "webButton",
              parentComponentId: "downloadAppScreen",
              style: {
                ...editorStylesData(state.formData.end.webButton),
              },
              data: {
                label: state.formData.end?.webButton?.value,
                url: state.formData.end?.webButtonURL ?? "",
              },
            },
          ]
        );
        break;
      default:
        configuration = [];
    }
    if (configuration.length == 0) return;
    const resp = yield upsertProgrammeConfig(programmeId, configuration);

    if (!resp.status || resp.status === 200) {
      const state = yield select();

      yield put(
        actions.setFilter({
          ...state.filterData,
          apiError: false,
          noticeDisplay: type === 0,
          noticeMessage: `Program updated successfully!`,
          screenLoading: type !== 0,
          programs:
            state.formData.formActive !== 1
              ? state.filterData.programs
              : state.filterData.programs.map((program) => {
                  if (program.id === programmeId) {
                    return {
                      ...program,
                      brandBackgroundColor:
                        state.formData.brand.colorBaground.code,
                      gradient: state.formData.brand.gradientBaground.code,
                      brandLogoBlob: state.formData.brand.logo.src,
                    };
                  } else {
                    return program;
                  }
                }),
        })
      );

      state.formData.formActive;
      yield put(
        actions.setForm({
          ...state.formData,
          startForm: false,
        })
      );
      if (type !== 0) {
        yield put(
          actions.getProgrammeConfig({
            programmeId: programmeId,
            componentId:
              type === 1
                ? state.formData.formActive - 1
                : state.formData.formActive + 1,
          })
        );
      }
    } else {
      const state = yield select();
      yield put(
        actions.setFilter({
          ...state.filterData,
          apiError: true,
        })
      );
    }
  } catch (error) {
    console.error(error);
    const state = yield select();
    yield put(
      actions.setFilter({
        ...state.filterData,
        apiError: true,
      })
    );
  }
}
