import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AppsIcon from "@mui/icons-material/Apps";
import DescriptionIcon from "@mui/icons-material/Description";
import PostAddIcon from "@mui/icons-material/PostAdd";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { actions } from "../state";

type FormDataType = {
  formActive: number;
  activeProgram: number;
  startForm: boolean;
};

type StateType = {
  formData: FormDataType;
  filterData: any;
};

const Menu: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const filterData = useSelector((state: StateType) => state.filterData);
  const dispatch = useDispatch();

  const handleDialog = () => {
    dispatch(
      actions.setFilter({
        ...filterData,
        dialogDisplay: true,
        dialogQuestion: "Do you want to cancel your changes?",
        dialogData: [0, "No", "Yes"],
      })
    );
  };

  const handleMenu = (value: number) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    dispatch(
      actions.setForm({
        ...formData,
        formActive: value,
      })
    );

    if (value <= 10) {
      dispatch(
        actions.setFilter({
          ...filterData,
          screenLoading: true,
        })
      );
      dispatch(
        actions.getProgrammeConfig({
          programmeId: formData.activeProgram,
          componentId: value,
        })
      );
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        overflow: "auto",
        width: "234px",
        height: "100vh",
        zIndex: "10",
        background: "#ffffff",
        boxShadow: "10px 0px 15px rgba(173,216,230,0.5)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          marginTop: "32px",
          background: "#EFF5FC",
          padding: "16px",
          color: "#3A3A3A",
          fontWeight: "700",
          fontSize: "18px",
        }}
      ></Box>
      {formData.formActive > 10 && (
        <List sx={{ marginTop: 2, padding: 0 }} component="nav">
          <ListItemButton
            onClick={() => handleMenu(11)}
            selected={formData.formActive === 11}
            className="mainMenu"
          >
            <ListItemIcon>
              <PostAddIcon />
            </ListItemIcon>
            <ListItemText primary={"Your Programs"} className="menu-item" />
          </ListItemButton>
          <ListItemButton
            onClick={() => handleMenu(12)}
            selected={formData.formActive === 12}
            className="mainMenu"
          >
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText primary={"All Programs"} className="menu-item" />
          </ListItemButton>
          {/* <ListItemButton 
            onClick={()=> handleMenu(13)} 
            selected={formData.formActive === 13}
            className='mainMenu'>
            <ListItemIcon>
              <StarBorderIcon />
            </ListItemIcon>
            <ListItemText primary={"Starred"} className="menu-item" />
          </ListItemButton> */}
        </List>
      )}
      {formData.formActive < 11 && (
        <List sx={{ marginTop: 2, padding: 0 }} component="nav">
          <Box>
            <ListItemButton
              onClick={() => handleMenu(1)}
              selected={formData.formActive === 1}
              className="mainMenu"
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Brand" className="menu-item" />
            </ListItemButton>
            <Box className="menu-divide" />

            <ListItemButton
              onClick={() => handleMenu(2)}
              selected={formData.formActive === 2}
              className="mainMenu"
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Welcome" className="menu-item" />
            </ListItemButton>
            <Box className="menu-divide" />

            <ListItemButton
              onClick={() => handleMenu(3)}
              selected={formData.formActive === 3}
              className="mainMenu"
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Begin" className="menu-item" />
            </ListItemButton>
            <Box className="menu-divide" />

            <ListItemButton
              onClick={() => handleMenu(4)}
              selected={formData.formActive === 4}
              className="mainMenu"
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Scan Tutorial" className="menu-item" />
            </ListItemButton>
            <Box className="menu-divide" />

            <ListItemButton
              onClick={() => handleMenu(5)}
              selected={formData.formActive === 5}
              className="mainMenu"
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary="Terms and Conditions"
                className="menu-item"
              />
            </ListItemButton>
            <Box className="menu-divide" />

            <ListItemButton
              onClick={() => handleMenu(6)}
              selected={formData.formActive === 6}
              className="mainMenu"
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary="Patient Information"
                className="menu-item"
              />
            </ListItemButton>
            <Box className="menu-divide" />

            <ListItemButton
              onClick={() => handleMenu(7)}
              selected={formData.formActive === 7}
              className="mainMenu"
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Face Scan" className="menu-item" />
            </ListItemButton>
            <Box className="menu-divide" />

            <ListItemButton
              onClick={() => handleMenu(8)}
              selected={formData.formActive === 8}
              className="mainMenu"
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="Results" className="menu-item" />
            </ListItemButton>
            <Box className="menu-divide" />

            <ListItemButton
              onClick={() => handleMenu(9)}
              selected={formData.formActive === 9}
              className="mainMenu"
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText
                primary="Product recommendations"
                className="menu-item"
              />
            </ListItemButton>
            <Box className="menu-divide" />

            <ListItemButton
              onClick={() => handleMenu(10)}
              selected={formData.formActive === 10}
              className="mainMenu"
            >
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary="End" className="menu-item" />
            </ListItemButton>
          </Box>
        </List>
      )}
      {formData.startForm && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0px",
          }}
          onClick={handleDialog}
        ></Box>
      )}
    </Box>
  );
};

export default Menu;
