/**
 * List Validate Message
 * @type {Array}
 */

export const validateData: { [key: string]: string } = {
  createProgramName: "Program name i  Required field enter your Program",
  createProgramRegion: "Required field select your Region",
  createProgramRegionOther: "Required field enter your Other Region",
  createProgramLocale: "Required field select your Locale",
  createProgramLocaleOther: "Required field enter your Other Locale",
};

export const apiListScreens: { [key: string]: string } = {
  1: "globals",
  2: "welcomeScreen",
  3: "beginScreen",
  4: "howScanWorkScreen",
  5: "termsAndConditionScreen",
  6: "patientInfoScreen",
  7: "globals",
  8: "resultScreen",
  9: "productScreen",
  10: "downloadAppScreen",
};

export const formListScreens: { [key: string]: string } = {
  1: "brand",
  2: "welcome",
  3: "begin",
  4: "howScanWork",
  5: "termsConditions",
  6: "form",
  7: "brand",
  8: "results",
  9: "product",
  10: "end",
};
