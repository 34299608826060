import React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import { onScreenClick } from "../../../data/utils/functions";

interface ColorType {
  type: string;
  code: string;
}
interface BrandType {
  closeBackground: ColorType;
  closeColor: ColorType;
}
interface WelcomeType {
  titleForm: string;
  display: boolean;
  img: any;
}
interface FormData {
  brand: BrandType;
  welcome: WelcomeType;
  startForm: boolean;
}

type StateType = {
  formData: FormData;
  filterData: any;
};

const Close: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 16,
        right: 16,
        zIndex: 1,
        borderRadius: 100,
      }}
    >
      <Box
        onClick={() => onScreenClick(formData?.welcome?.display ? 2 : 3)}
        sx={{
          padding: "8px",
          lineHeight: 0,
          cursor: "pointer",
        }}
      >
        <ClearIcon
          sx={{
            width: "24px",
            height: "24px",
            color: formData.brand.closeColor.code,
          }}
        />
      </Box>
    </Box>
  );
};

export default Close;
