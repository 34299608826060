import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import { TextType } from "../../data/const/dataType";
import { editorStyles, onScreenClick } from "../../data/utils/functions";
import Close from "./components/Close";
import Logo from "./components/Logo";
import { actions } from "../../state";
import ClearIcon from "@mui/icons-material/Clear";
import { Checkbox, Typography } from "@mui/material";

interface HowScanWorkType {
  titleForm: string;
  header: TextType;
  content: TextType;
  image: any;
  button: TextType;
  checkbox: TextType;
  contentBottom: TextType;
}

interface FormData {
  howScanWork: HowScanWorkType;
  brand: any;
}

type StateType = {
  formData: FormData;
};

const HowScanWork: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const [isCheck, updateCheckBox] = useState(false);
  const dispatch = useDispatch();

  const handleScreenTerms = () => {
    dispatch(
      actions.setForm({
        ...formData,
        formActive: 5,
      })
    );
  };

  const removeImage = () => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        howScanWork: {
          ...formData.howScanWork,
          image: {
            src: "",
            upload: null,
            blob: "",
            link: "",
          },
        },
      })
    );
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Close />
      <Logo />
      <Box
        sx={{
          ...editorStyles(formData.howScanWork.header),
          borderWidth: 0,
          background: "none",
          borderStyle: "solid",
          borderRadius: 2,
          textAlign: "center",
          padding: 0,
          whiteSpace: "pre-line",
        }}
      >
        {formData.howScanWork.header.value}
      </Box>
      <Box
        sx={{
          margin: "24px 40px",
        }}
      >
        {formData.howScanWork.image.src ? (
          <Box
            sx={{
              position: "relative",
              width: "430px",
              height: "300px",
            }}
          >
            <img
              src={formData.howScanWork.image.src}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt=""
            />
            <Box
              sx={{
                position: "absolute",
                display: "flex",
                width: "30px",
                height: "30px",
                top: "-10px",
                right: "-10px",
                background: "black",
                borderRadius: "30px",
                alignItems: "center",
                justifyContent: "center",
                opacity: 0.8,
              }}
              onClick={removeImage}
            >
              <ClearIcon
                sx={{
                  width: "20px",
                  height: "20px",
                  color: "white",
                }}
              />
            </Box>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                ...editorStyles(formData.howScanWork.content),
                // borderWidth: 1,
                // borderStyle: "solid",
                borderRadius: 2,
                padding: 2,
                background: "rgba(0,0,0,0.1)", //236,49,35
                overflow: "scroll",
                maxHeight: "240px",
                whiteSpace: "pre-line",
              }}
            >
              {formData.howScanWork.content.value}
            </Box>
          </>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "15px",
            alignItems: "center",
          }}
        >
          <Checkbox
            checked={isCheck}
            onChange={() => updateCheckBox(!isCheck)}
            style={{
              color: formData.howScanWork.checkbox.color, // Green color for checked, default MUI color for unchecked
            }}
          />
          <Box
            sx={{
              ...editorStyles(formData.howScanWork.checkbox),
              background: "transparent",
              textAlign: "left",
            }}
          >
            {`${formData.howScanWork.checkbox.value}`}
            <span
              onClick={() => onScreenClick(5)}
              style={{ textDecoration: "underline" }}
            >
              privacy policy and terms & conditions
            </span>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          margin: "24px 40px",
          textAlign: "center",
        }}
      >
        <Box
          onClick={() => (isCheck ? onScreenClick(6) : null)}
          sx={{
            ...editorStyles(formData.howScanWork.button),
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 1000,
            padding: "8px 52px",
            cursor: "pointer",
            display: "inline-block",
            whiteSpace: "pre-line",
            opacity: isCheck ? "1" : "0.5",
          }}
        >
          {formData.howScanWork.button.value}
        </Box>
        <Box
          sx={{
            ...editorStyles(formData.howScanWork.contentBottom),
            borderWidth: 0,
            background: "none",
            borderStyle: "solid",
            borderRadius: 1000,
            padding: "8px 52px",
            opacity: 0.8,
            textAlign: "center",
            whiteSpace: "pre-line",
            marginTop: "10px",
          }}
        >
          {formData.howScanWork.contentBottom.value}
        </Box>
      </Box>
    </Box>
  );
};

export default HowScanWork;
