import React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import Brand from "./Brand";
import Begin from "./Begin";
import HowScanWork from "./HowScanWork";
import TermsConditions from "./TermsConditions";
import Results from "./Results";
import End from "./End";
import Form from "./Form";
import Product from "./Product";
import pepsico from "../../data/assets/images/logoloading.png";
import { LinearProgress } from "@mui/material";
import Footer from "./components/Footer";
import Welcome from "./Welcome";

export interface LoadingType {
  loading: boolean;
  colorBaground: any;
  gradientBaground: any;
}

interface FormData {
  formActive: number;
  welcome: LoadingType;
  brand: LoadingType;
  begin: LoadingType;
  howScanWork: LoadingType;
  termsConditions: LoadingType;
  form: LoadingType;
  results: LoadingType;
  product: LoadingType;
  end: LoadingType;
}

type StateType = {
  formData: FormData;
};

const Preview: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const filterData = useSelector((state: any) => state.filterData);

  return (
    <Box
      sx={{
        background: `linear-gradient(to bottom left, ${
          formData.brand.gradientBaground.code ??
          formData.brand.colorBaground.code
        } ,${formData.brand.colorBaground.code} 80%)`,
      }}
      style={{
        width: "512px",
        position: "relative",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        style={{
          width: "512px",
          height: "683px",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {formData.formActive === 1 && formData.brand.loading && <Brand />}
        {formData.formActive === 2 && formData.welcome.loading && <Welcome />}
        {formData.formActive === 3 && formData.begin.loading && <Begin />}
        {formData.formActive === 4 && formData.howScanWork.loading && (
          <HowScanWork />
        )}
        {formData.formActive === 5 && formData.termsConditions.loading && (
          <TermsConditions />
        )}
        {formData.formActive === 6 && formData.form.loading && <Form />}
        {formData.formActive === 7 && <Brand />}
        {formData.formActive === 8 && formData.results.loading && <Results />}
        {formData.formActive === 9 && formData.product.loading && <Product />}
        {formData.formActive === 10 && formData.end.loading && <End />}
      </Box>
      {formData.formActive !== 9 && <Footer />}
      {filterData?.screenLoading && <AppLoading />}
    </Box>
  );
};

const AppLoading = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      width: "100%",
      height: "100%",
      background: "rgba(255,255,255,.7)",
      zIndex: 999,
      opacity: "0.9",
    }}
  >
    <Box sx={{ width: "50px" }}>
      <img
        src={pepsico}
        style={{ maxWidth: "50px", marginBottom: "4px" }}
        alt=""
      />
      <LinearProgress sx={{ height: "2px", color: "#f1d1d7" }} />
    </Box>
  </Box>
);

export default Preview;
