import React, { ChangeEvent, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { TextField, Select, MenuItem, Grid } from "@mui/material";
import { ProgramType } from "../../data/const/dataType";
import Validate from "../../components/Validate";
import { actions } from "../../state";

interface FilterData {
  programs: ProgramType[];
  regions: ProgramType[];
  locales: ProgramType[];
  createProgram: ProgramType;
  loadingForm: boolean;
  validateForm: boolean;
  startForm: boolean;
  currentProgram: any;
  programValue: {
    brandBackgroundColor: string;
    brandLogoBlob: string;
    footerLogoBlob: string;
    default: boolean;
    id: string;
    localCode: string;
    localeName: string;
    regionCode: string;
    regionName: string;
    self: boolean;
    state: string;
    title: string;
  } | null;
}

interface FormProps {
  type?: string;
  createType?: string;
}

type StateType = {
  filterData: FilterData;
};

const Form: React.FC<FormProps> = ({ type, createType }) => {
  const filterData = useSelector((state: StateType) => state.filterData);
  const dispatch = useDispatch();

  const { programValue } = filterData;

  useEffect(() => {
    if (createType == "clone" && programValue) {
      dispatch(
        actions.setFilter({
          ...filterData,
          startForm: true,
          validateForm: false,
          createProgram: {
            ...filterData.createProgram,
            title: programValue?.title || "",
            region: programValue?.regionName || "",
            locale: programValue?.localeName || "",
            regionOther: "",
            localeOther: "",
          },
        })
      );
    }
  }, [programValue, createType]);

  const handleEditName = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setFilter({
        ...filterData,
        startForm: true,
        validateForm: false,
        createProgram: {
          ...filterData.createProgram,
          title: event.target.value,
        },
      })
    );
  };

  const handleData = (type: string, id: number | string) => {
    if (type === "regions") {
      dispatch(
        actions.setFilter({
          ...filterData,
          startForm: true,
          validateForm: false,
          createProgram: {
            ...filterData.createProgram,
            region: id,
            regionOther: "",
          },
        })
      );
    }
    if (type === "locales") {
      dispatch(
        actions.setFilter({
          ...filterData,
          startForm: true,
          validateForm: false,
          createProgram: {
            ...filterData.createProgram,
            locale: id,
            localeOther: "",
          },
        })
      );
    }
  };

  const handleOtherRegion = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setFilter({
        ...filterData,
        startForm: true,
        validateForm: false,
        createProgram: {
          ...filterData.createProgram,
          regionOther: event.target.value,
        },
      })
    );
  };

  const handleOtherLocale = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      actions.setFilter({
        ...filterData,
        startForm: true,
        validateForm: false,
        createProgram: {
          ...filterData.createProgram,
          localeOther: event.target.value,
        },
      })
    );
  };

  useEffect(() => {
    if (filterData.currentProgram.id != 0) {
      filterData.createProgram.id = filterData.currentProgram.id;
      filterData.createProgram.title = filterData.currentProgram.title;
      filterData.createProgram.region = filterData.currentProgram.regionName;
      filterData.createProgram.locale = filterData.currentProgram.localeName;
    }
  }, [filterData.currentProgram.id]);

  return (
    <Grid item xs={8} sx={{ position: "relative" }}>
      <Box>
        <Box sx={{ color: "#616161", fontSize: "12px", fontWeight: "400" }}>
          Program Name
        </Box>
        <TextField
          fullWidth
          placeholder="Program"
          onChange={handleEditName}
          size="small"
          value={filterData.createProgram.title}
          sx={{ mt: 1, mb: 1 }}
        />
        {filterData.validateForm && (
          <Validate
            display={!filterData.createProgram.title}
            message={"createProgramName"}
          />
        )}
      </Box>
      <Box>
        <Box
          sx={{
            color: "#616161",
            fontSize: "12px",
            marginTop: "12px",
            fontWeight: "400",
          }}
        >
          Regions
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Select
              fullWidth
              label="Select Region"
              value={filterData.createProgram.region}
              size="small"
              sx={{ mt: 1, mb: 1 }}
              placeholder="Select Region"
            >
              <MenuItem value={0}>Select Region</MenuItem>
              {filterData.regions.map((filter: ProgramType, index: number) => (
                <MenuItem
                  value={filter.title}
                  onClick={() => handleData("regions", filter.title)}
                  key={index}
                >
                  {filter.title}
                </MenuItem>
              ))}
              <MenuItem
                value="other"
                onClick={() => handleData("regions", "other")}
              >
                Other Region
              </MenuItem>
            </Select>
            {filterData.validateForm && (
              <Validate
                display={filterData.createProgram.region === 0}
                message={"createProgramRegion"}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled={filterData.createProgram.region !== "other"}
              size="small"
              value={filterData.createProgram.regionOther}
              placeholder="Other Region"
              sx={{ mt: 1, mb: 1 }}
              onChange={handleOtherRegion}
            />
            {filterData.validateForm &&
              filterData.createProgram.region === "other" && (
                <Validate
                  display={!filterData.createProgram.regionOther}
                  message={"createProgramRegionOther"}
                />
              )}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Box
          sx={{
            color: "#616161",
            fontSize: "12px",
            marginTop: "12px",
            fontWeight: "400",
            zIndex: "999999",
          }}
        >
          Language
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Select
              fullWidth
              label="Select Language"
              value={filterData.createProgram.locale}
              disabled={filterData.createProgram.region === 0}
              size="small"
              sx={{ mt: 1, mb: 1 }}
            >
              <MenuItem value={0}>Select Language</MenuItem>
              {filterData.locales.map((filter: ProgramType, index: number) => (
                <MenuItem
                  value={filter.title}
                  onClick={() => handleData("locales", filter.title)}
                  key={index}
                >
                  {filter.title}
                </MenuItem>
              ))}
              <MenuItem
                value="other"
                onClick={() => handleData("locales", "other")}
              >
                Other Locale
              </MenuItem>
            </Select>
            {filterData.validateForm &&
              filterData.createProgram.region !== 0 && (
                <Validate
                  display={filterData.createProgram.locale === 0}
                  message={"createProgramLocale"}
                />
              )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled={filterData.createProgram.locale !== "other"}
              value={filterData.createProgram.localeOther}
              size="small"
              placeholder="Other Language"
              sx={{ mt: 1, mb: 1 }}
              onChange={handleOtherLocale}
            />
            {filterData.validateForm &&
              filterData.createProgram.locale === "other" && (
                <Validate
                  display={!filterData.createProgram.localeOther}
                  message={"createProgramLocaleOther"}
                />
              )}
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Form;
