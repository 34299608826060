import React from "react";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { TextType } from "../../data/const/dataType";
import { editorStyles, onScreenClick } from "../../data/utils/functions";
import { actions } from "../../state";
import ClearIcon from "@mui/icons-material/Clear";

interface WelcomeType {
  titleForm: string;
  display: boolean;
  img: any;
  button: TextType;
}

interface FormData {
  welcome: WelcomeType;
  formActive: number;
}

type StateType = {
  formData: FormData;
  filterData: any;
};

const Welcome: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const dispatch = useDispatch();

  const removeImage = () => {
    dispatch(
      actions.setForm({
        ...formData,
        startForm: true,
        welcome: {
          ...formData.welcome,
          img: {
            src: "",
            upload: null,
            blob: "",
            link: "",
          },
        },
      })
    );
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        marginY: "40px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {formData.welcome.img.src && (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "185px",
            }}
          >
            <img
              src={formData.welcome.img.src}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
              alt=""
            />
            <Box
              sx={{
                position: "absolute",
                display: "flex",
                width: "30px",
                height: "30px",
                top: "-26px",
                right: "10px",
                background: "black",
                borderRadius: "30px",
                alignItems: "center",
                justifyContent: "center",
                opacity: 0.8,
              }}
              onClick={removeImage}
            >
              <ClearIcon
                sx={{
                  width: "20px",
                  height: "20px",
                  color: "white",
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          marginTop: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          onClick={() => onScreenClick(3)}
          sx={{
            ...editorStyles(formData.welcome.button),
            padding: "8px 52px",
            borderRadius: 1000,
            borderWidth: 1,
            borderStyle: "solid",
            cursor: "pointer",
            whiteSpace: "pre-line",
          }}
        >
          {formData.welcome.button.value}
        </Box>
      </Box>
    </Box>
  );
};

export default Welcome;
