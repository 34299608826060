import React from "react";
import { useSelector } from "react-redux";
import Brand from "./Brand";
import Begin from "./Begin";
import HowScanWork from "./HowScanWork";
import TermsConditions from "./TermsConditions";
import Form from "./Form";
import Results from "./Results";
import End from "./End";
import ScanFace from "./ScanFace";
import Product from "./Product";
import Welcome from "./Welcome";

interface LoadingType {
  loading: boolean;
}

interface FormData {
  formActive: number;
  brand: LoadingType;
  welcome: LoadingType;
  begin: LoadingType;
  howScanWork: LoadingType;
  termsConditions: LoadingType;
  form: LoadingType;
  results: LoadingType;
  product: LoadingType;
  end: LoadingType;
}

type StateType = {
  formData: FormData;
};

const Forms: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);

  return (
    <>
      {formData.formActive === 1 && formData.brand.loading && <Brand />}
      {formData.formActive === 2 && formData.welcome.loading && <Welcome />}
      {formData.formActive === 3 && formData.begin.loading && <Begin />}
      {formData.formActive === 4 && formData.howScanWork.loading && (
        <HowScanWork />
      )}
      {formData.formActive === 5 && formData.termsConditions.loading && (
        <TermsConditions />
      )}
      {formData.formActive === 6 && formData.form.loading && <Form />}
      {formData.formActive === 7 && <ScanFace />}
      {formData.formActive === 8 && formData.results.loading && <Results />}
      {formData.formActive === 9 && formData.product.loading && <Product />}
      {formData.formActive === 10 && formData.end.loading && <End />}
    </>
  );
};

export default Forms;
