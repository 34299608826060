import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSelector, useDispatch } from "react-redux";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Tab, Tabs } from "@mui/material";
import { ProgramType } from "../data/const/dataType";
import Dialog from "./Dialog";
import Preview from "../modules/Preview";
import Form from "../modules/CreateProgram/Form";
import { actions } from "../state";

interface FormData {
  formActive: number;
  preview: boolean;
  activeProgram: number;
  startForm: boolean;
}

interface FilterData {
  programs: ProgramType[];
  settingFormTab: number;
  settingFormDisplay: boolean;
  currentProgram: any;
  createProgram: any;
}

type StateType = {
  formData: FormData;
  filterData: FilterData;
};

const Demo: React.FC = () => {
  const formData = useSelector((state: StateType) => state.formData);
  const filterData = useSelector((state: StateType) => state.filterData);

  const dispatch = useDispatch();

  const handleEditProgram = () => {
    dispatch(
      actions.setForm({
        ...formData,
        preview: false,
      })
    );
  };

  const handleCloneProgram = () => {
    dispatch(
      actions.setFilter({
        ...filterData,
        openModal: true,
        startForm: false,
        loadingForm: false,
        createFormTab: 1,
        programValue: filterData?.programs?.find(
          (program: ProgramType) => program.id == formData?.activeProgram
        ),
        // isClone: true,
        // programToClone:{
        //   programmeId: filterData.currentProgram.id,
        //   programmeName: filterData.currentProgram.title,
        //   regionName: filterData.currentProgram.regionName,
        //   localeName: filterData.currentProgram.localeName,
        // }
      })
    );
  };

  const handleSaveDraft = () => {
    dispatch(
      actions.setFilter({
        ...filterData,
        screenLoading: true,
      })
    );
    dispatch(actions.getUpsertProgrammeConfig({ type: 0 }));
  };

  const handleSavePublish = () => {
    dispatch(
      actions.setFilter({
        ...filterData,
        screenLoading: true,
      })
    );
    dispatch(
      actions.getUpdateProgrammeState({
        programmeId: formData.activeProgram,
        programState: "PUBLISHED",
      })
    );
  };

  const handleOpenSettingForm = (status: boolean) => {
    if (formData.startForm) {
      dispatch(
        actions.setFilter({
          ...filterData,
          dialogDisplay: true,
          dialogQuestion: "Do you want to cancel your changes?",
          dialogData: [0, "No", "Yes"],
        })
      );
    } else {
      const activeProgram = filterData.programs.find(
        (item: any) => item.id === formData.activeProgram
      );
      dispatch(
        actions.setFilter({
          ...filterData,
          settingFormDisplay: status,
          settingFormTab: 0,
          validateForm: false,
          createProgram: {
            id: activeProgram?.id,
            title: activeProgram?.title,
            region: activeProgram?.regionName,
            locale: activeProgram?.localeName,
          },
        })
      );
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    dispatch(
      actions.setFilter({
        ...filterData,
        settingFormTab: newValue,
      })
    );
  };

  const handleClose = () => {
    dispatch(
      actions.setFilter({
        ...filterData,
        settingFormDisplay: false,
      })
    );
  };

  const handleSave = () => {
    let regionName =
      filterData.createProgram.region != "other"
        ? filterData.createProgram.region
        : filterData.createProgram.regionOther;
    let localeName =
      filterData.createProgram.locale != "other"
        ? filterData.createProgram.locale
        : filterData.createProgram.localeOther;
    dispatch(
      actions.getUpdateProgramme({
        programmeId: filterData.createProgram.id,
        programmeName: filterData.createProgram.title,
        programmeDescription: filterData.createProgram.description,
        regionName: regionName,
        localeName: localeName,
      })
    );
  };

  const handleDelete = () => {
    dispatch(
      actions.setFilter({
        ...filterData,
        dialogDisplay: true,
        dialogQuestion: "Do you want to delete this program?",
        dialogData: [0, "No", "Yes"],
      })
    );
  };

  const handleDeleteConfimed = () => {
    dispatch(
      actions.setFilter({
        ...filterData,
        loading: true,
        dialogDisplay: false,
      })
    );
    dispatch(
      actions.getDeleteProgramme({ programmeId: formData.activeProgram })
    );
  };

  return (
    <>
      {formData.formActive < 11 && (
        <Box sx={{ marginTop: "64px", marginLeft: "234px" }}>
          <Box
            sx={{
              padding: "24px",
              display: "flex",
              justifyContent: "space-between",
              width: formData.preview
                ? "calc(100vw - 234px)"
                : "calc(100vw - 234px - 400px)",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                color: "#616161",
                fontSize: "18px",
                fontWeight: "700",
              }}
            >
              {
                (
                  filterData.programs.find(
                    (e: ProgramType) => e.id === formData.activeProgram
                  ) || { title: "Default Program" }
                ).title
              }
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {!formData.preview && (
                <>
                  <Dialog handleConfirm={handleDeleteConfimed} />
                  <Button
                    onClick={handleDelete}
                    variant="contained"
                    style={{
                      borderColor: "#005CBC",
                      borderWidth: "1px",
                      borderStyle: "solid",
                      color: "#005CBC",
                      background: "#F3F7FE",
                      fontSize: "14px",
                      fontWeight: "700",
                      boxShadow: "none",
                    }}
                  >
                    Delete Program
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSaveDraft}
                    disabled={!formData.startForm}
                    style={{
                      marginLeft: "12px",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Save Draft
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSavePublish}
                    disabled={formData.startForm}
                    style={{
                      marginLeft: "12px",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Publish
                  </Button>
                  <Box
                    style={{
                      marginLeft: "12px",
                      background: "#ffffff",
                      color: "#005CBC",
                      fontSize: "14px",
                      borderRadius: "4px",
                      fontWeight: "700",
                      borderStyle: "solid",
                      borderColor: "#D7E5F8",
                      borderWidth: "1px",
                      cursor: "pointer",
                      position: "relative",
                      boxSizing: "content-box",
                      display: "flex",
                    }}
                  >
                    <MoreVertIcon
                      style={{ padding: "8px" }}
                      onClick={() => handleOpenSettingForm(true)}
                    />
                    {filterData.settingFormDisplay && (
                      <Box
                        style={{
                          background: "#ffffff",
                          position: "absolute",
                          padding: "24px",
                          boxShadow: "rgba(0, 92, 188, 0.4) 0px 0px 24px 0px",
                          width: "calc((100vw - 234px) - 480px)",
                          right: "16px",
                          borderRadius: "8px",
                          zIndex: "999",
                          top: "56px",
                        }}
                      >
                        <Tabs
                          value={filterData.settingFormTab}
                          onChange={handleTabChange}
                          indicatorColor="primary"
                          textColor="primary"
                          sx={{
                            marginTop: "16px",
                            width: "100%",
                            borderBottom: "1px solid #e0e0e0",
                          }}
                        >
                          <Tab
                            label="Edit Details"
                            sx={{
                              display: "flex",
                              alignItems: "baseline",
                              width: "50%",
                              maxWidth: "50%",
                              textTransform: "none",
                            }}
                          />
                          {/* <Tab 
                            label="Permissions" 
                            sx={{ 
                              display: "flex",
                              alignItems: "baseline",
                              width: '50%',
                              maxWidth: '50%',
                              textTransform: 'none',
                            }}
                          /> */}
                        </Tabs>
                        <Box
                          role="tabpanel"
                          hidden={filterData.settingFormTab !== 0}
                        >
                          <Box
                            style={{
                              margin: "24px 0 0",
                            }}
                          >
                            <Form />
                          </Box>
                        </Box>
                        {/* <Box role="tabpanel" hidden={filterData.settingFormTab !== 1}>                        
                          <Box style={{
                            margin: "24px 0 0",
                            color: "#000000",
                            fontSize: "14px",
                            fontWeight: "300",
                          }}>
                            Who can edit the profile
                          </Box>
                        </Box> */}
                        <Box
                          sx={{
                            mt: 3,
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            variant="outlined"
                            className="createProgram"
                            onClick={handleClose}
                            sx={{ mr: 2 }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            className="createProgram"
                            onClick={handleSave}
                            color="primary"
                          >
                            Save Changes
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </>
              )}
              {formData.preview && (
                <>
                  <Button
                    variant="contained"
                    onClick={handleEditProgram}
                    style={{
                      marginLeft: "12px",
                      background: "#005CBC",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleCloneProgram}
                    style={{
                      marginLeft: "12px",
                      background: "#005CBC",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Clone
                  </Button>
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              padding: "24px",
              display: "flex",
              justifyContent: "space-between",
              width: "calc(100vw - 234px - 532px)",
              alignItems: "center",
              paddingTop: 0,
            }}
          >
            <Box
              sx={{
                color: "#616161",
                fontSize: "18px",
                fontWeight: "700",
              }}
            >
              Preview
            </Box>
          </Box>
          <Box
            sx={{
              background: "#ffffff",
              margin: "0 24px 24px",
              borderRadius: "8px",
              boxShadow: "0px 0px 15px rgba(173,216,230,0.5)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "16px 0",
            }}
          >
            <Preview />
          </Box>
        </Box>
      )}
      {filterData.settingFormDisplay && (
        <Box
          onClick={() => handleOpenSettingForm(false)}
          style={{
            background: "#999999",
            opacity: "0.3",
            position: "fixed",
            width: "100%",
            height: "100%",
            zIndex: "99",
          }}
        ></Box>
      )}
    </>
  );
};

export default Demo;
